"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPartialDealSheetConfigUpdateFromDealer = void 0;
var getPartialDealSheetConfigUpdateFromDealer = function (dealer) {
    if (!dealer)
        return {};
    return {
        dealerName: dealer.name,
        externalDealerId: dealer.providerDealerId,
        showTitleFees: !!dealer.showTitleFees,
        showRegistrationFees: !!dealer.showRegistrationFees,
    };
};
exports.getPartialDealSheetConfigUpdateFromDealer = getPartialDealSheetConfigUpdateFromDealer;
